/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:31:42 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:31:42 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import Typography from "../../atoms/Typography";

import "./styles.scss";


const PolicyHeader = (props) => {
    const { title } = props;
    return (
        <>
            <Row className={"policy-header wrapper-top"}>
                <Col xs={12} className={"d-flex align-items-center"}>
                    <Image src={'/gotradie-logo-2.png'} className={"policy-header-logo "} />
                </Col>
            </Row>
            <Row className={"policy-header"}>
                <Col xs={12}  className={"d-flex align-items-center"}>
                    <Typography Tag={"h6"} className={"policy-header-title"}>{title}</Typography>
                </Col>
            </Row>
        </>
    )

}

export default PolicyHeader;

/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 3:06:50 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:06:50 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTemplate from "../../components/templates/BlankTemplate";

import Quote from "../../components/organisms/Quote";

import Models from "../../models";

import './styles.scss';


import api from "../../api";
import _ from "lodash";
const source = _.get(api, "quotes");




const QuotePage = () => {
  const { code } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [quote, setQuote] = useState(null);


  useEffect(() => {
    console.log("load")
    if (source) {
      setLoading(true);
      source
        .getQuote({ code: code })
        .then((res) => {
          setLoading(false);
          console.log(res);
          setQuote(Models.QuotesModel(res.data));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return (
    <PageTemplate>
      <Helmet>
        <title>{`GoTradie | Quote `}</title>
      </Helmet>
      {(quote !== null) && (
        <>

          <Quote quote={quote} />
        </>
      )}

    </PageTemplate>
  );
};

export default QuotePage;

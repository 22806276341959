/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:31:42 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:31:42 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import Typography from "../../atoms/Typography";

import "./styles.scss";


const PolicyBody = (props) => {
    return (
        <>
            <Row className={"policy-body pt-2"}>
                <Col xs={12} >
                    {props.children}
                </Col>
            </Row>
        </>
    )

}

export default PolicyBody;

/*
 * File: index.js
 * File Created: Sunday, 11th October 2020 10:40:50 am
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Sunday, 11th October 2020 10:40:50 am
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Row } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import "./styles.scss";


const Loader = (props) => {

    const {isLoading, mode} = props;

    let image = '/quickpay_redirect.png';
    let description = '';
    switch (mode) {
        case 'initiate-redirect': 
            image = '/quickpay_redirect.png';
            description = `We're getting everything ready, you'll be redirected to Quickapay shortly`;
            break;
        case 'unpayable-unsent-invoice':
            image = '/payment_unsuccesful.png';
            description = `The invoice still seems to be unsent. Therefore the payment details could not be fetched.`;
            break;
        case 'unpayable-paid-invoice':
            image = '/payment_unsuccesful.png';
            description = `Looks like you are trying to access the payment details of an invoice that has already been paid`;
            break;
        case 'backend-error':
            image = '/redirect-no-connect.png';
            description = `Looks like we are having some technical difficulty on gathering relevant information to proceed to payments. Please contact our support team for further assistance`;
            break;
        default:
            break;
    }

    return (
        <>
            <Row>
                <div class="loading">
                    <div class={isLoading ? "loader" : "loader-spacer"}></div>
                </div>
            </Row>
            <Row className={"redirecting"}>
                <Image src={image} className={"redirecting-descriptive-img"}/>
                <Image src={'/gtlogo.png'} className={"redirecting-logo"}/>
                <Image src={'/qplogo.png'} className={"redirecting-logo"}/>
                <Typography Tag={"p"} className={"redirecting-text"}>{description}</Typography>
            </Row>
        </>
    );

}

export default Loader;
import React from "react";
import "./style.scss";
import clsx from "clsx";

const Link = (props) => {
    const {children, href, className, letterSpacing = 0} = props;
    return (
        <a
            href={href}
            className={clsx(
                className,
                letterSpacing && `letter-spacing-${letterSpacing}`
            )}
        >
            {children}
        </a>
    );
};

export default Link;

/*
 * File: index.js
 * File Created: Sunday, 11th October 2020 10:40:50 am
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Sunday, 11th October 2020 10:40:50 am
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import "./styles.scss";


const Loader = (props) => {

    const {isLoading, mode, data} = props;

    let image, title, subtitle, details;

    switch (mode) {
        case 'initiate-settlement': 
            image = '/quickpay_redirect.png';
            title = `Syncing..`;
            subtitle = `Please wait while we sync your payment details`;
            break;
        case 'settlement-success':
            image = '/payment_successful.png';
            title = `Invoice paid!`;
            subtitle = `Your money has been transfered`;
            details = `All sorted! Checkback into the app to kick off your next project`;
            break;
        case 'settlement-failure':
            image = '/payment_unsuccesful.png';
            title = `Payment declined!`;
            subtitle = `Please try again or try a different payment method`;
            break;
        case 'backend-error':
            image = '/redirect-no-connect.png';
            subtitle = `Looks like we are having some technical difficulty on gathering relevant information to proceed to payments. Please contact our support team for further assistance.`;
            break;
        default:
            image = '/quickpay_redirect.png';
            break;
    }

    return (
        <>
            <Row>
                <div className="loading">
                    <div className={isLoading ? "loader" : "loader-spacer"}></div>
                </div>
            </Row>
            <Row className={"syncing"}>
                <Image src={'/gtlogo.png'} className={"syncing-logo"}/>
                <Image src={image} className={"syncing-descriptive-img"}/>
            </Row>
            <Row className={"syncing"}>
                {title && <Typography Tag={"p"} className={"syncing-title"}>{title}</Typography>}
                {subtitle && <Typography Tag={"p"} className={"syncing-subtitle"}>{subtitle}</Typography>}
                {data !== null && <div className="box">
                    <div className="box-row">
                        <Typography Tag={"p"} className={"box-bold"}>Invoice Id</Typography>
                        <Typography Tag={"p"} className={"box-text"}>{data.value}</Typography>
                    </div>
                    <div className="box-row">
                        <Typography Tag={"p"} className={"box-bold"}>Payment Reference</Typography>
                        <Typography Tag={"p"} className={"box-text"}>{data.token}</Typography>
                    </div>
                </div>}
                {details && <Typography Tag={"p"} className={"syncing-subtitle"}>{details}</Typography>}
            </Row>
        </>
    );

}

export default Loader;
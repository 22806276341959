/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:28:36 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:28:36 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Alert } from "react-bootstrap";
import QuoteHeader from "../../molecules/QuoteHeader";
import QuoteTable from "../../molecules/QuoteTable";
import QuoteFooter from "../../molecules/QuoteFooter";

import "./styles.scss";


const QuotesBody = (props) => {
const {quote} = props;
    return (
        <Container className={"pt-2 py-2"}>
          <QuoteHeader {...quote["quote_header"]} />
          <QuoteTable {...quote["quote_body"]}/>
          <QuoteFooter {...quote["quote_footer"]}/>
        </Container>
    )

}

export default QuotesBody;

/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 3:06:50 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:06:50 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTemplate from "../../components/templates/BlankTemplate";

import Invoice from "../../components/organisms/Invoice";

import Models from "../../models";

import './styles.scss';


import api from "../../api";
import _ from "lodash";
const source = _.get(api, "invoice");




const InvoicePage = () => {
  const { code } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);


  useEffect(() => {
    if (source) {
      setLoading(true);
      source
        .getInvoice({ code: code })
        .then((res) => {
          setLoading(false);
          console.log(res);
          setInvoice(Models.InvoiceModel(res.data));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return (
    <PageTemplate>
      <Helmet>
        <title>{`GoTradie | Invoice `}</title>
      </Helmet>
      {(invoice !== null) && (
        <>

          <Invoice invoice={invoice} />
        </>
      )}

    </PageTemplate>
  );
};

export default InvoicePage;
/*
 * File: App.js
 * File Created: Saturday, 10th October 2020 3:04:38 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:04:38 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';
import QuotePage from './pages/QuotePage';
import InvoicePage from './pages/InvoicePage';
import InvoicePaymentPage from './pages/InvoicePaymentPage';
import InvoiceSettlementPage from './pages/InvoiceSettlementPage';
import PageNotFoundPage from './pages/404Page';
import TCPage from './pages/TermsAndConditionsPage';
import PPPage from './pages/PrivacyPolicyPage';
import EmailVerifyPage from './pages/EmailVerifyPage';

import {
    ROUTE_QUOTE,ROUTE_INVOICE, ROUTE_INVOICE_PAYMENT, ROUTE_INVOICE_SETTLEMENT, ROUTE_TERMS_AND_CONDITIONS, ROUTE_PRIVACY_POLICY, ROUTE_VERIFY_EMAIL
  } from "./constants/Routes";

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>

          <Switch>
            <Route path={ROUTE_QUOTE} component={QuotePage} />
            <Route path={ROUTE_INVOICE} component={InvoicePage} />
            <Route path={ROUTE_INVOICE_PAYMENT} component={InvoicePaymentPage} />
            <Route path={ROUTE_INVOICE_SETTLEMENT} component={InvoiceSettlementPage} />
            <Route path={ROUTE_TERMS_AND_CONDITIONS} component={TCPage} />
            <Route path={ROUTE_PRIVACY_POLICY} component={PPPage} />
            <Route path={ROUTE_VERIFY_EMAIL} component={EmailVerifyPage} />
            <Route path="*" component={PageNotFoundPage} />)
          </Switch>

      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;

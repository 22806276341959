/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 3:06:50 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:06:50 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTemplate from "../../components/templates/BlankTemplate";
import Payment from "../../components/organisms/Payment"

import './styles.scss';

import api from "../../api";
import _ from "lodash";
const source = _.get(api, "invoice");
const report_source = _.get(api, "reporting");

const InvoicePaymentPage = () => {
  const { code } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [mode, setMode] = useState('')

  const EVENT_STATUS = {
    FAIL: 'fail',
    SUCCESS: 'success'
  }

  useEffect(() => {
    if (source) {
      setLoading(true);
      setMode('initiate-redirect')
      source
        .getInvoice({ code: code })
        .then((res) => {
          const invoice =  res.data.invoices ? res.data.invoices : {}
          if(invoice['invoice-status'] == 'sent' || invoice['invoice-status'] == 'overdue'){
            source
                .getInvoicePaymentInfo({ code: code })
                    .then((res2 => {
                        const payment_info = res2.data;
                        reportEvent(EVENT_STATUS.SUCCESS, '', code);
                        window.location.href = payment_info['payments-url'];
                    }))
                    .catch((err2) => {
                        console.log('Error! Payment info fetching error.', err2);
                        setMode("backend-error");
                        reportEvent(EVENT_STATUS.FAIL, `Error fetching payment info`, code, err2);
                    })
                    .finally(() => {
                      setLoading(false);
                    })
          }else if(invoice['invoice-status'] == 'draft' || invoice['invoice-status'] == 'ready' ){
            setLoading(false);
            setMode("unpayable-unsent-invoice");
            reportEvent(EVENT_STATUS.FAIL, `Invoice is in unsent state`, code);
          }else if(invoice['invoice-status'] == 'paid'){
            setLoading(false);
            setMode("unpayable-paid-invoice");
            reportEvent(EVENT_STATUS.FAIL, `Invoice in paid state`, code);
          }else{
            setLoading(false);
            setMode("backend-error");
            reportEvent(EVENT_STATUS.FAIL, `Invoice is in ${invoice['invoice-status']} state`, code);
          }
        })
        .catch((err) => {
          console.log('Error! Invoice fetching error.', err);
          setLoading(false);
          setMode("backend-error");
          reportEvent(EVENT_STATUS.FAIL, `Unable to fetch the invoice`, code, err);
        });
    }
  }, []);

  function reportEvent(status, message, code, data = 'none') {
    report_source.reportEvent({
      'event-type': 'payments-landing',
      'user-id': 'system',
      'user-event': { data },
      'data': { status: `${status} to navigate`, 'error': message },
      'source-type': 'invoice',
      'source-id': code
    });
  }

  return (
    <PageTemplate>
      <Helmet>
        <title>{`GoTradie | Invoice Payment Redirect`}</title>
      </Helmet>
      <>
        <Payment isLoading={isLoading} mode={mode} />
      </>
    </PageTemplate>
  );
};

export default InvoicePaymentPage;
/*
 * File: index.js
 * File Created: Friday, 11th September 2020 3:57:51 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Friday, 11th September 2020 3:57:51 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import * as _ from "lodash";
import * as accounting from "accounting-js";
import moment from 'moment';

const FilterByText = (data = [], txt = "", key = "name", key2 = "nickName") => {
  const result = data.filter((v) => {
    if (v[key] !== undefined) {
      const str = `${v[key]}`.toLowerCase();
      if (str.includes(txt.toLowerCase())) {
        return true;
      } else if (v[key2] !== undefined) {
        const str = `${v[key2]}`.toLowerCase();
        return str.includes(txt.toLowerCase());
      } else {
        return false;
      }
    } else {
      return true;
    }
  });
  return result;
};





const MakeMakeFilterValuesToReqBody = (ar) => {
  const result = ar.reduce((map, obj) => {
    map[obj.key] = obj.value.id;
    return map;
  }, {});
  return result;
};

const getUniqueArray = (ar, key) => {
  return _.uniqBy(ar, key);
};

const modifyArray = (ar, search_key, newObj) => {
  const mdar = ar;

  const index = _.findIndex(mdar, (o) => {
    return o[search_key] == newObj[search_key];
  });

  // Replace item at index using native splice
  if (index > -1) {
    const upObj = { ...mdar[index], ...newObj };
    console.log("mod", upObj);
    mdar.splice(index, 1, upObj);
  }
  return mdar;
};



const getTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const replaceByDefault = (v, key, DefaultVal = 0) => {
  if (v[key] !== undefined && v[key] !== null) {
    return v[key];
  } else {
    return DefaultVal;
  }
};

const replaceEmptyWithUndefined = (value)=> {
  if (typeof value === 'object' && _.isEmpty(value)) {
      return undefined;
  } else if (typeof value === 'string' && value === "") {
      return undefined;
  } else if (Array.isArray(value) && value.length === 0) {
      return undefined;
  }
  return value;
}
function replaceUndefinedWithDefault(value, defaultValue) {
  return ((value == undefined || value == "NONE") ? defaultValue : value)
}

const convertToPrice = (amt)=> {
return accounting.formatMoney(amt,{ symbol: "$",  format: "%s %v" });
}

function convertDateToCurrnetTimeZone(datetime, format = "YYYY-MM-DD HH:mm", format_of = "YYYY-MM-DD HH:mm") {
  var gmtDateTime = moment.utc(datetime, format_of)
  var local = gmtDateTime.local().format(format);
  return local;
}


export default {
  convertDateToCurrnetTimeZone:convertDateToCurrnetTimeZone,
  FilterByText: FilterByText,
  MakeFilterValuesToReqBody: MakeMakeFilterValuesToReqBody,
  getUniqueArray: getUniqueArray,
  modifyArray: modifyArray,
  getTitleCase: getTitleCase,
  replaceByDefault: replaceByDefault,
  replaceEmptyWithUndefined:replaceEmptyWithUndefined,
  replaceUndefinedWithDefault:replaceUndefinedWithDefault,
  convertToPrice:convertToPrice
};

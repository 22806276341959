import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTemplate from "../../components/templates/BlankTemplate";
import VerifyEmail from "../../components/organisms/VerifyEmail";

import api from "../../api";
import _ from "lodash";
const source = _.get(api, "emailVerify");

const EmailVerifyPage = () => {
  const { userId, token } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    console.log("load")
    if (source) {
      setLoading(true);
      source
        .verifyEmail({ 
          'user-id': userId, 
          'email-token': token
        })
        .then((res) => {
          setLoading(false);
          console.log(res);
          setVerificationStatus(res.data['validation-status']);
        })
        .catch((err) => {
          console.log(err);
          const errorObject = JSON.parse(err.request.response)
          if (errorObject.message === 'The email was already validated') {
            setVerificationStatus('already-validated')
          } else {
            setVerificationStatus('error')
          }
          setLoading(false);
        });
    }
  }, []);

  return (
    <PageTemplate>
      <Helmet>
        <title>{`GoTradie | Email Verification`}</title>
      </Helmet>
        <>
          <VerifyEmail status={verificationStatus} />
        </>
    </PageTemplate>
  );
};

export default EmailVerifyPage;

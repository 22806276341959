/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 3:07:14 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:07:14 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';

import PageTemplate from "../../components/templates/BlankTemplate";


import { Container, Row, Col } from 'react-bootstrap';
import './styles.scss';

const PageNotFoundPage = () => {

  return (
    <PageTemplate>
      <Container className="page-not-found container h-100 px-4">
        <Row className="row d-flex justify-content-center align-items-center vh-100">
          <Col md className={'text-center'}>
      
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  );
};

export default PageNotFoundPage;
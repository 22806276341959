/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 3:06:50 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:06:50 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTemplate from "../../components/templates/BlankTemplate";

import Policy from "../../components/organisms/Policy";

import './styles.scss';





const TermsAndConditionsPage = () => {

  return (
    <PageTemplate page_name="policy">
        <Helmet>
            <title>{`GoTradie | T&C `}</title>
        </Helmet>
        <>
            <Policy title={"Terms and Conditions"}>
                <div>
<p>Here are our terms and conditions. Please contact us here if you have any other questions. Thanks for connecting with GoTradie.</p>
<p>These are the inaugural GoTradie terms & conditions (January 2021). They outline GoTradie’s and Your obligations and responsibilities on the GoTradie Platform. We will summarise specific changes here as we make changes to future versions.</p>
<h2>1. USER AGREEMENT</h2>
<p>1.1.	GoTradie operates an digital platform allowing Users (Seeker) to connect through the GoTradie Platform with other Users (Tradie)  who provide Services.</p>
<p>1.2.	Please read these terms and all Policies including our Community Guidelines carefully before using the GoTradie Platform. These Policies are incorporated into this Agreement by reference.</p>
<p>1.3.	All defined terms in this Agreement have the meaning given to them in the GoTradie Glossary.</p>
<p>1.4.	Your download and use of the GoTradie application software (App) and the websites, content, products and services (Services) offered from time to time by GoTradie Trading Pty Ltd (ABN XX XXX XXX XXX) (We/Us/Our) are governed by the terms and condition set out in this agreement (Agreement). Use of the App and the Services is also governed by Our privacy policy http://www.fixitfaster.com/privacy which is incorporated into this Agreement by reference. Please download and use the App and the Services only if you have read, understood and agree to the terms of this Agreement.</p>
<p>1.5.	You acknowledge and agree that this Agreement constitutes a binding agreement between you and Us. Please read the terms of the Agreement carefully before proceeding to download the App or use any of our Services. If you do not agree to these terms, please do not download and use the App or any of our Services.</p>
<p>1.6.	We reserve the right to change the terms of this Agreement at any time and without liability. The most recent version of the Agreement will be available at all times on our website and the App's profile page on the iTunes App Store and Google Play where this App is available for download. Please check our website and the App's profile page on iTunes/Google Play regularly for changes, as your continued use of the App and/or the Services following the posting of such changes signifies your acceptance of the modified Agreement terms.</p>
<p>1.7.	This Agreement, as amended, will remain in full force and effect while you use the App and the Services. We reserve the right to withdraw the App or change the content of the App in any way and at any time, with or without notice to you, without liability.</p>
<h2>2.	SCOPE OF GOTRADIE SERVICES</h2>
<p>2.1.	GoTradie provides the GoTradie Platform to enable Seekers to publish Posted Jobs.</p>
<p>2.2.	Tradies may make an Offer in response to a Posted Job. Some parts of Offer details may be made publicly available, including to internet users who are not Users.</p>
<p>2.3.	A Seeker may revoke or modify a Posted Job at any time before he/she accepts an Offer.</p>
<p>2.4.	If a Seeker accepts a Tradie Quote on the GoTradie Platform, a Job Contract is created between the Tradie and the Seeker.</p>
<p>2.5.	Upon creation of a Job Contract, the Seeker and Tradie  ……  </p>
<p>2.6.	Once the Job Contract is created, the Tradie and Seeker may vary the Job Contract on the GoTradie Platform. The Seeker and Tradie are encouraged to use GoTradie's private messaging system to amend or vary the Job Contract (including the Agreed Price) or to otherwise communicate.</p>
<p>2.7.	Once the Services are complete, the Seeker marks the job as complete on the GoTradie Platform and the Tradie can issue an invoice directly to the Seeker.</p>
<p>2.8.	After the Job Contract is completed, the parties are encouraged to provide feedback regarding the Services on the GoTradie Platform.</p>
<p>2.9.	GoTradie may also provide a Location Search Assist feature enabling Seekers to submit an Offer for Services.</p>
<p>2.10.	An Offer submitted by a Seeker using Location Search Assist may be notified to other Users and such Users may elect to make an Instant Claim of it.</p>
<p>2.11.	A Seeker may revoke or modify its Offer in using Location Search Assist, including for Recurring Services, at any time before a Tradie makes an Instant Claim. GoTradie reserves the right to cancel all Posted Jobs made prior to the revocation or modification.</p>
<p>2.12.	Admin </p>
<p>2.13.	Payment </p>
<h2>3.	GOTRADIE'S ROLE AND OBLIGATIONS</h2>
<p>3.1.	GoTradie provides the GoTradie Platform only, enabling Users to publish Posted Jobs and make Offers on Posted Jobs produce Quotes and Invoices and get Paid.</p>
<p>3.2.	GoTradie only permits individuals over 18 years of age to become Users.</p>
<p>3.3.	Users must be natural persons but can specify within their account description that they represent a business entity. </p>
<p>3.4.	At its absolute discretion, GoTradie may refuse to allow any person to register or create an account with GoTradie or cancel or suspend or modify any existing account including if GoTradie reasonably forms the view that a User's conduct (including a breach of this Agreement) is detrimental to the operation of the GoTradie Platform.</p>
<p>3.5.	Registering and creating an account with GoTradie is free for a Seeker and there is no charge for a Seeker to post Jobs. There is a monthly subscription fee for a Tradie for the use of the find  Job, do Admin and get paid services. </p>
<p>3.6.	GoTradie accepts no liability for any aspect of the Seeker and Tradie interaction, including but not limited to the description, performance or delivery of Services.</p>
<p>3.7.	GoTradie has no responsibility and makes no warranty as to the truth or accuracy of any aspect of any information provided by Users. While we validate that the Trade Licence details are current and valid via Services NSW other certificate details, including, but not limited to, the ability of Tradies to perform Jobs or supply items, or the honesty or accuracy of any information provided by Seekers or the Seekers' ability to pay for the Services requested is not validated by GoTradie.</p>
<p>3.8.	Except for liability in relation to any Non-excludable Condition, the GoTradie Service is provided on an "as is" basis, and without any warranty or condition, express or implied. To the extent permitted by law, we and our suppliers specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.</p>
<p>3.9.	GoTradie has no obligation to any User to assist or involve itself in any dispute between Users, although may do so to improve User experience.</p>
<h2>4.	USER OBLIGATIONS</h2>
<p>4.1.	You will at all times:</p>
<p>4.1.1.	comply with this Agreement (including all Policies) and all applicable laws and regulations;</p>
<p>4.1.2.	only post accurate information on the GoTradie Platform;</p>
<p>4.1.3.	ensure that You are aware of any laws that apply to You as a Seeker or Tradie, or in relation to using the GoTradie Platform.</p>
<p>4.2.	You agree that any content (whether provided by GoTradie, a User or a third party) on the GoTradie Platform may not be used on third party sites or for other business purposes without GoTradie's prior permission.</p>
<p>4.3.	You must not use the GoTradie Platform for any illegal or immoral purpose.</p>
<p>4.4.	You must maintain control of Your GoTradie account at all times. This includes not allowing others to use Your account, or by transferring or selling Your account or any of its content to another person.</p>
<p>4.5.	You grant GoTradie an unrestricted, worldwide, royalty-free licence to use, reproduce, modify and adapt any content and information posted on the GoTradie Platform for the purpose of publishing material on the GoTradie Platform and as otherwise may be required to provide the GoTradie Service, for the general promotion of the GoTradie Service, and as permitted by this Agreement.</p>
<p>4.6.	You agree that any information posted on the GoTradie Platform must not, in any way whatsoever, be potentially or actually harmful to GoTradie or any other person. Harm includes, but is not limited to, economic loss that will or may be suffered by GoTradie.</p>
<p>4.7.	Without limiting any provision of this Agreement, any information You supply to GoTradie or publish in a Posted Job (including as part of an Offer) must be up to date and kept up to date and must not:</p>
<p>4.7.1.	*be false, inaccurate or misleading or deceptive;</p>
<p>4.7.2.	*be fraudulent or involve the sale of counterfeit or stolen items;</p>
<p>4.7.3.	*infringe any third party's copyright, patent, trademark, trade secret or other proprietary rights or intellectual property rights, rights of publicity, confidentiality or privacy;</p>
<p>4.7.4.	*violate any applicable law, statute, ordinance or regulation (including, but not limited to, those governing export and import control, consumer protection, unfair competition, criminal law, antidiscrimination and trade practices/fair trading laws);</p>
<p>4.7.5.	*be defamatory, libellous, threatening or harassing;</p>
<p>4.7.6.	*be obscene or contain any material that, in GoTradie's sole and absolute discretion, is in any way inappropriate or unlawful, including, but not limited to obscene, inappropriate or unlawful images; or</p>
<p>4.7.7.	*contain any malicious code, data or set of instructions that intentionally or unintentionally causes harm or subverts the intended function of any GoTradie Platform, including, but not limited to viruses, trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, modify, delete, detrimentally interfere with, surreptitiously intercept, access without authority or expropriate any system, data or Personal Information.</p>
<p>4.8.	GoTradie Platform may from time to time engage location-based or map-based functionality. The GoTradie Platform may display the location of Seekers and Tradies to persons browsing the GoTradie Platform. A Seeker should never disclose personal details such as the Seeker's full name, street number, phone number or email address in a Posted Job or in any other public communication on the GoTradie Platform.</p>
<p>4.9.	If You are a Tradie, You must have the right to provide Services under a Job Contract and to work in the jurisdiction where the Services are performed. You must comply with tax and regulatory obligations in relation to any payment received under a Job Contract.</p>
<p>4.10.	The parties to a Job Contract may agree to amend the Agreed Price through the GoTradie Platform.</p>
<p>4.11.	If a Tradie agrees to pay some costs of completing the Services (such as equipment to complete the Services), the Tradie is solely responsible for obtaining any reimbursement from the Seeker. GoTradie advises Tradies not to agree to incur costs in advance of receiving the payment for these costs, unless the Tradie is confident the Seeker will reimburse the costs promptly.</p>
<p>4.12.	For the proper operation of the GoTradie Platform (including insurance, proper pricing and compliance with Policies), the Tradie must ensure that, if it subcontracts any part of the performance of the Services to a third party in accordance with a Job Contract, then the Tradie is responsible for managing that third party.</p>
<p>4.13.	GoTradie determines at its sole discretion that You have breached any obligation under this clause 3 or that You have breached one or more Job Contracts, it reserves the rights to remove any content, Posted Job or Offer You have submitted to the GoTradie Service or cancel or suspend Your account and/or any Job Contracts.</p>
<h2>5.	App intellectual property </h2>
<p>5.1.	The App is protected by copyright, trade mark and other intellectual property laws. You acknowledge that:- all intellectual property rights in the App throughout the world belong to Us or Our suppliers or affiliates;- this Agreement does not transfer any title to intellectual property in the App to you; and- your only rights to in respect of the App are as set out in this Agreement.</p>
<h2>6.	USE OF THE APP</h2>
<p>6.1.	The App uses geolocation software to find trades people and other service providers near your location who have flagged themselves as being available. Trades persons and service providers who download and subscribe to the App consent to the use of the App for the purpose of determining their geographic location while they are checked in to the App.</p>
<p>6.2.	The App is a matching service only. Trades persons and service providers who subscribe to the App are not employed by Us. We accept no responsibility for payment of trades persons or service providers or for resolution of complaints concerning work conducted by them.</p>
<h2>7.	COST OF THE APP AND SUBSCRIPTION FEES </h2>
<p>7.1.	The App is free to download by anyone, however certain features of the Service require you to pay a subscription fee. Subscription fees vary depending on whether you are a trades person/service provider (Service Provider) or a non-service provider (Customer).</p>
<p>7.2.	Our current subscription fees are set out on our website at www.GoTradie.com.au. We reserve the right to vary our subscription fees from time to time and will provide reasonable prior notice to you in the event that your subscription fees increase.</p>
<p>7.3.	Upon the creation of a Job Contract, the Tradie and Seeker have agreed to the Tradie Quotation Scope of work including Terms and Conditions.</p>
<p>7.4.	A Tradies pays a Subscription fee to access all or part of the features of the GoTradie platform</p>
<p>7.5.	GoTradie may restrict a Tradie’s account until all monthly subscription fees have been paid.</p>
<h2>8.	PAYMENTS AND REFUNDS</h2>
<p>8.1.	If the Job Contract is cancelled for any reason (by a Seeker, a Tradie or under this Agreement) prior to the commencement of the Job Contract, then </p>
<p>8.2.	5</p>
<p>8.3.	GoTradie may take up to 14 days to return the Agreed Price (less the Cancellation Admin Fee, if applicable) to the Seeker.</p>
<h2>9.	BUSINESS PARTNERS</h2>
<p>9.1.	GoTradie may enter into agreements with Business Partners and may seek to engage Tradies in the provision of Business Services. Tradies who agree to perform Business Services for Business Partners acknowledge and agree that GoTradie and the Business Partner may on-sell Services supplied to third parties for an increased fee.</p>
<p>9.2.	Business Partners may require Tradies providing Business Services to be approved or hold particular qualifications. GoTradie may assist Business Partners to locate suitably qualified Tradies. GoTradie makes no warranty that it will promote any or all suitably qualified Tradies to Business Partners.</p>
<p>9.3.	Business Partners may require Tradies to enter into a Business Partner Contract before providing Business Services.</p>
<p>9.4.	Where a Tradie accepts a Posted Job with a Business Partner:</p>
<p>9.4.1.	(a) the Tradie must provide Business Services to the Business Partner in accordance with the Job Contract and any applicable Business Partner Contract; and</p>
<p>9.4.2.	(b) the terms of the Business Partner Contract will prevail to the extent of any inconsistency.</p>
<h2>10.	PAYMENT FACILITY</h2>
<p>10.1.	GoTradie uses a Payment Provider to operate the Payment Account.</p>
<p>10.2.	In so far as it is relevant to the provision of the Payment Account, the terms at https://stripe.com/au/ssa are incorporated into this Agreement and will prevail over this Agreement to the extent of any inconsistency in relation to the provision of the Payment Account.</p>
<p>10.3.	If GoTradie changes its Payment Provider You may be asked to agree to any further additional terms with those providers. If you do not agree to them, you will be given alternative means of payment.</p>
<h2>11.	THIRD PARTY SERVICES</h2>
<p>11.1.	GoTradie may from time to time include Third Party Services on the GoTradie Platform. These Third Party Services are not provided by GoTradie.</p>
<p>11.2.	Third Party Services are offered to Users pursuant to the third party's terms and conditions. Third Party Services may be promoted on the GoTradie Platform as a convenience to our Users who may find the Third Party Services of interest or of use.</p>
<p>11.3.	If a User engages with any Third Party Service provider, the agreement will be directly between the User and that Third Party Service provider.</p>
<p>11.4.	GoTradie makes no representation or warranty as to the Third Party Services. However, to help us continue to improve our GoTradie Platform, Users may inform GoTradie of their Third Party Service experience here.</p>
<h2>12.	SERVICE NSW TRADE LICENSE VERIFICATION </h2>
<p>GoTradie may use Identity Verification Services.</p>
<p>12.1.	You agree that GoTradie Identity Verification Services may not be fully accurate as all GoTradie Services are dependent on User-supplied information and/or information or Verification Services provided by third parties.</p>
<p>12.2.	You are solely responsible for identity verification and GoTradie accepts no responsibility for any use that is made of an GoTradie Identity Verification Service.</p>
<p>12.3.	GoTradie Identity Verification Services may be modified at any time.</p>
<p>12.4.	The GoTradie Platform may also include a User-initiated feedback system to help evaluate Users.</p>
<p>12.5.	GoTradie may make Badges available to Tradies. The available Badge can be requested by the Tradie via the GoTradie Platform, and arranged on behalf of the Tradie and issued by GoTradie, for a fee. Obtaining Badges may be conditional upon the provision of certain information or documentation by the Tradie and determined by GoTradie or a third party verifier which shall be governed by its terms.</p>
<p>12.6.	You acknowledge that Badges are point in time checks and may not be accurate at the time it is displayed. You acknowledge that to the extent You relied on a Badge in entering into a Job Contract, you do so aware of this limitation. You should seek to verify any Badge with the Tradie prior to commencing the Job.</p>
<p>12.7.	It remains the Tradie's responsibility to ensure that information or documentation it provides in obtaining a Badge is true and accurate and must inform GoTradie immediately if a Badge is no longer valid.</p>
<p>12.8.	GoTradie may, at its discretion, issue Badges to Tradies for a fee.</p>
<p>12.9.	The issue of a Badge to a Tradie remains in the control of GoTradie and the display and use of a Badge is licensed to the Tradie for use on the GoTradie Platform only. Any verification obtained as a result of the issue of a Badge may not be used for any other purpose outside of the GoTradie Platform.</p>
<p>12.10.	GoTradie retains the discretion and right to not issue, or remove without notice, a Badge if You are in breach of any of the terms of this Agreement, the Badge has been issued incorrectly, obtained falsely, has expired, is no longer valid or for any other reason requiring its removal by GoTradie.</p>
<h2>13.	INSURANCE </h2>
<p>13.1.	GoTradie may offer its Users an opportunity to obtain insurance for certain Job Contracts. All such insurance will be offered by a third party. Any application and terms and conditions for such third party insurance will be displayed on the GoTradie website when they are available. GoTradie confirms that all insurance policies are Third Party Services and governed by further terms set out for Third Party Services.</p>
<p>13.2.	GoTradie does not represent that any insurance it acquires or which is offered via the GoTradie Platform is adequate or appropriate for any particular User.</p>
<p>13.3.	Each User must make its own enquiries about whether any further insurance is required and Tradies remain responsible for ensuring that they have, and maintain, sufficient insurance to cover the Services provided to other Users of the GoTradie Platform.</p>
<h2>14.	FEEDBACK</h2>
<p>14.1.	You can complain about any comment made on the GoTradie Platform using the 'Report' function of the GoTradie Platform or contact GoTradie via the GoTradie Platform.</p>
<p>14.2.	GoTradie is entitled to suspend or terminate Your account at any time if GoTradie, in its sole and absolute discretion, is concerned by any feedback about You, or considers Your feedback rating to be problematic for other GoTradie Users.</p>
<h2>15.	LIMITATION OF LIABILITY</h2>
<p>15.1.	Please see Your Country Specific Terms for the applicable exclusions and limitations of liability.</p>
<h2>16.	PRIVACY</h2>
<p>16.1.	Third Party Service providers will provide their service pursuant to their own Privacy Policy. Prior to acceptance of any service from a third party, You must review and agree to their terms of service including their privacy policy.</p>
<p>16.2.	In order to ensure GoTradie can reduce the incidence of fraud and other behaviour in breach of the Community Guidelines, GoTradie reserves the right to ask Users to verify themselves in order to remain a User.</p>
<p>16.3.	A full Privacy Statement is available at www.GoTradie.com.au </p>
<h2>17.	MODIFICATIONS TO THE AGREEMENT</h2>
<p>17.1.	GoTradie may modify this Agreement or the Policies (and update the GoTradie pages on which they are displayed) from time to time. GoTradie will send notification of such modifications to Your GoTradie account or advise You the next time You login.</p>
<p>17.2.	When You actively agree to amended terms (for example, by clicking a button saying "I accept") or use the GoTradie Platform in any manner, including engaging in any acts in connection with a Job Contract, the amended terms will be effective immediately. In all other cases, the amended terms will automatically be effective 30 days after they are initially notified to You.</p>
<p>17.3.	If You do not agree with any changes to this Agreement (or any of our Policies), You must either terminate your account or You must notify GoTradie who will terminate Your GoTradie account and stop using the GoTradie Service.</p>
<h2>18.	NO AGENCY</h2>
<ul><li>No agency, partnership, joint venture, employee-employer or other similar relationship is created by this Agreement. In particular You have no authority to bind GoTradie, its related entities or affiliates in any way whatsoever. GoTradie confirms that all Third Party Services that may be promoted on the GoTradie Platform are provided solely by such Third Party Service providers. To the extent permitted by law, GoTradie specifically disclaims all liability for any loss or damage incurred by You in any manner due to the performance or non-performance of such Third Party Service.</li></ul>
<h2>19.	NOTICES</h2>
<p>19.1.	Except as stated otherwise, any notices must be given by registered ordinary post or by email, either to GoTradie's contact address as displayed on the GoTradie Platform, or to GoTradie Users' contact address as provided at registration. Any notice shall be deemed given:</p>
<p>19.1.1.	if sent by email, 24 hours after email is sent, unless the User is notified that the email address is invalid or the email is undeliverable, and</p>
<p>19.1.2.	if sent by pre-paid post, three Business Days after the date of posting, or on the seventh Business Day after the date of posting if sent to or posted from outside the jurisdiction in which You have Your GoTradie Platform account.</p>
<p>19.2.	Notices related to performance of any Third Party Service must be delivered to such third party as set out in the Third Party Service provider's terms and conditions.</p>
<h2>20.	MEDIATION AND DISPUTE RESOLUTION</h2>
<p>20.1.	GoTradie encourages You to try and resolve disputes (including claims for returns or refunds) with other Users directly. Accordingly, You acknowledge and agree that GoTradie may, in its absolute discretion, provide Your information as it decides is suitable to other parties involved in the dispute.</p>
<p>20.2.	 If a dispute arises with another User, You must co-operate with the other User and make a genuine attempt to resolve the dispute.</p>
<p>20.3.	GoTradie may elect to assist Users resolve disputes. Any User may refer a dispute to GoTradie. You must co-operate with any investigation undertaken by GoTradie. GoTradie reserves the right to make a final determination (acting reasonably) based on the information supplied by the Users and direct the Payment Provider to make payment accordingly. You may raise your dispute with the other User or GoTradie's determination in an applicable court or tribunal.</p>
<p>20.4.	GoTradie has the right to hold any Agreed Price that is the subject of a dispute in the Payment Account, until the dispute has been resolved.</p>
<p>20.5.	GoTradie may provide access to a Third Party Dispute Service. If such a service is provided, either party may request the other party to submit to the Third Party Dispute Service if the parties have failed to resolve the dispute directly. Terms and conditions for the Third Party Dispute Service will be available on request. The Third Party Dispute Service is a Third Party Service and Users are responsible for paying any costs associated with the Third Party Dispute Service in accordance with the Third Party Dispute Service terms and conditions.</p>
<p>20.6.	Disputes with any Third Party Service provider must proceed pursuant to any dispute resolution process set out in the terms of service of the Third Party Service provider.</p>
<p>20.7.	If You have a complaint about the GoTradie Service please contact us here.</p>
<p>20.8.	If GoTradie provides information about other Users to You for the purposes of resolving disputes under this clause, You acknowledge and agree that such information will be used only for the purpose of resolving the dispute (and no other purpose) and that you will be responsible and liable to GoTradie for any costs, losses or liabilities incurred by GoTradie in relation to any claims relating to any other use of information not permitted by this Agreement.</p>
<h2>21.	TERMINATION</h2>
<p>21.1.	Either You or GoTradie may terminate your account and this Agreement at any time for any reason.</p>
<p>21.2.	Termination of this Agreement does not affect any Job Contract that has been formed between GoTradie Users.</p>
<p>21.3.	Third Party Services are conditional upon, and governed by, Third Party Service provider terms and conditions.</p>
<p>21.4.	 Sections X (Fees), XX (Limitation of Liability) and XX (Mediation and Dispute Resolution) and any other terms which by their nature should continue to apply, will survive any termination or expiration of this Agreement.</p>
<p>21.5.	If Your account or this Agreement are terminated for any reason then You may not without GoTradie's consent (in its absolute discretion) create any further accounts with GoTradie and we may terminate any other accounts You operate.</p>
<h2>22.	APPLE'S RESPONSIBILITY AND RIGHTS</h2>
<p>22.1.	If you are downloading and using the App on an Apple device, you acknowledge and agree that:</p>
<p>22.1.1.	Apple, Inc (Apple) and its subsidiaries are not parties to this Agreement, are not responsible for the App or its content, and have no obligation whatsoever to furnish any maintenance and support services with respect to the App. However, please be aware that Apple and its subsidiaries are third-party beneficiaries of this Agreement. Upon your acceptance of the terms of this Agreement, Apple will have the right (and will be deemed to have accepted that right) to enforce this Agreement against you as a third party beneficiary of the Agreement;</p>
<p>22.1.2.	Apple is not responsible for addressing any questions, comments or claims relating to the App and your use of the App;</p>
<p>22.1.3.	to the maximum extent permitted by applicable law, Apple will have no warranty obligation whatsoever with respect to the App, or any responsibility for any claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any such warranty;</p>
<p>22.1.4.	Apple is not responsible for addressing any claims made by you or a third party relating to the App or your possession and/or use of the App, including but not limited to:</p>
<p>22.1.4.1.1.	product liability claims;</p>
<p>22.1.4.1.2.	any claim that the App fails to conform to any applicable legal or regulatory requirement; and</p>
<p>22.1.4.1.3.	claims arising under consumer protection or similar legislation</p>
<p>22.2.	Apple is not responsible for the investigation, defence, settlement and discharge of any intellectual property infringement claim made by a third party arising from your possession and use of the App, and that, except as set out in this Agreement, Mars will be responsible for dealing with such claims.</p>
<h2>23.	GENERAL</h2>
<p>23.1.	This Agreement is governed by the laws specified in Your Country Specific Terms.</p>
<p>23.2.	The provisions of this Agreement are severable, and if any provision of this Agreement is held to be invalid or unenforceable, such provision may be removed and the remaining provisions will be enforceable.</p>
<p>23.3.	This Agreement may be assigned or novated by GoTradie to a third party without your consent. In the event of an assignment or novation the User will remain bound by this Agreement.</p>
<p>23.4.	This Agreement sets out the entire understanding and agreement between the User and GoTradie with respect to its subject matter.</p>
<h2>APPENDIX A:</h2>
<h2>MODEL JOB CONTRACT</h2>
<p>The terms used in this Job Contract have the meaning set out in the GoTradie Glossary. A Job Contract is created in accordance with the GoTradie Agreement. Unless otherwise agreed, the Seeker and the Tradie enter into a Job Contract on the following terms:</p>
<h2>24.	COMMENCEMENT DATE AND TERM</h2>
<p>24.1.	The Job Contract is created when the Seeker accepts the Tradie's Offer on a Posted Job to provide Services. When using Search Assist, the Job Contract is created when the Tradie makes an Instant Claim.</p>
<p>24.2.	The Contract will continue until terminated in accordance with clause 7.</p>
<h2>25.	SERVICES</h2>
<p>25.1.	The Tradie will perform Services in a proper and workmanlike manner.</p>
<p>25.2.	The Tradie must perform the Services at the time and location agreed.</p>
<p>25.3.	The parties must perform their obligations in accordance with any other terms or conditions agreed by the parties during or subsequent to the creation of the Job Contract.</p>
<p>25.4.	The parties acknowledge that the Job Contract is one of a licensed professional service where the Seeker selected the Tradie to perform the Services. Therefore the Tradie must not subcontract any part of the Services to any third party without the Seeker's consent.</p>
<p>25.5.	The Tradie remains responsible and liable at all times to the Seeker for any acts or omissions of a subcontractor as if those acts or omissions had been made by the Tradie.</p>
 
<h2>26.	WARRANTIES</h2>
<p>26.1.	Each party warrants that the information provided in the creation of the Job Contract is true and accurate.</p>
<p>26.2.	The Tradie warrants that they have (and any subcontractor has) the right to work and provide Services and hold all relevant licences in the jurisdiction where the Services are performed.</p>
<h2>27.	PAYMENT OR CANCELLATION</h2>
<p>27.1.	Upon the creation of the Job Contract, the Seeker must pay the Agreed Price into the Payment Account.</p>
<p>27.2.	Upon the Services being completed, the Tradie will provide notice on the GoTradie Platform.</p>
<p>27.3.	Invoicing </p>
<p>27.4.	What will happen re Job Cancelation? </p>
<h2>28.	LIMITATION OF LIABILITY</h2>
<p>28.1.	Except for liability in relation to a breach of a Non-excludable Condition, the parties exclude all Consequential Loss arising out of or in connection to the Services, and any claims by any third person, or the Job Contract, even if the party causing the breach knew the loss was possible or the loss was otherwise foreseeable.</p>
<p>28.2.	Subject to any insurance or agreement to the contrary, the liability of each party to the other except for a breach of any Non-Excludable Condition is capped at the Agreed Price.</p>
<h2>29.	DISPUTES</h2>
<p>29.1.	If a dispute arises between the parties, the parties will attempt to resolve the dispute within 14 days by informal negotiation (by phone, email or otherwise).</p>
<p>29.2.	If the parties are unable to resolve the dispute in accordance with clause 6.1, either party may refer the dispute to GoTradie and act in accordance with clause 18 of the GoTradie Agreement.</p>
 
<h2>30.	TERMINATION OF CONTRACT</h2>
<p>30.1.	The Job Contract will terminate when:</p>
<p>30.1.1.	the Services are completed and the Agreed Price is released from the Payment Account;</p>
<p>30.1.2.	a party is terminated or suspended from the GoTradie Platform, at the election of the other party;</p>
<p>30.1.3.	otherwise agreed by the parties or the Third Party Dispute Service; or</p>
<p>30.1.4.	notified by GoTradie in accordance with the party's GoTradie Agreement.</p>
<h2>31.	APPLICATION OF POLICIES</h2>
<p>31.1.	The parties incorporate by reference the applicable Policies.</p>
<h2>32.	GOVERNING LAW</h2>
<p>32.1.	The Job Contract is governed by the laws of the jurisdiction where the Posted Job was posted on the GoTradie Platform.</p>

<p>Initial Draft Created December 2020 </p>
<h2>APPENDIX B:</h2>
<h2>COUNTRY SPECIFIC TERMS</h2>
<h2>33.	Australian Terms</h2>
<p>33.1.	If You are a User who has Your GoTradie Platform account in (or the Services are performed in) Australia then the following terms will also apply to or may vary this Agreement to the extent specified:</p>
<p>33.1.1.	a reference to A$, $A, dollar or $ is to Australian currency;</p>
<p>33.1.2.	"GoTradie" means GoTradie Trading Pty Ltd ACN 643 372 114;</p>
<p>33.1.3.	"GoTradie Parent" means GoTradie Holding Pty Ltd ACN 620 206 171</p>
<p>33.1.4.	"ACL" means the Australian Consumer Law;</p>
<p>33.1.5.	"Consumer Guarantees" means the consumer guarantees contained in Part 3-2 of the ACL;</p>
<p>33.1.6.	"Personal Information" has the same meaning given to it in the Privacy Act 1988 (Cth);</p>
<p>33.1.7.	This Agreement is governed by the laws of New South Wales, Australia. You and GoTradie submit to the exclusive jurisdiction of the courts of New South Wales, Australia; and</p>
<p>33.1.8.	The following is added as clause 3.3 in the Model Contract of Appendix A "3.3 The parties incorporate the Consumer Guarantees into the Job Contract, even if they are not already incorporated by law.";</p>
<p>33.1.9.	The exclusions and limitations of liability shall be as follows:</p>
<p>33.1.9.1.	Except for liability in relation to breach of Non-excludable Condition, to the extent permitted by law, GoTradie specifically disclaims all liability for any loss or damage (actual, special, direct, indirect and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed (including, without limitation, loss or damage relating to any inaccuracy of information provided, or the lack of fitness for purpose of any goods or service supplied), arising out of or in any way connected with any transaction between Seekers and Tradies.</p>
<p>33.1.9.2.	Except for liability in relation to a breach of any Non-excludable Condition, to the extent permitted by law, GoTradie specifically disclaims all liability for any loss or damage (actual, special, direct, indirect and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed (including, without limitation, loss or damage relating to any inaccuracy of information provided, or the lack of fitness for purpose of any goods or service supplied), arising out of or in any way connected with any transaction between You and any Third Party Service provider who may be included from time to time on the GoTradie Platform.</p>
<p>33.1.9.3.	Except for liability in relation to a breach of any Non-excludable Condition, and to the extent permitted by law, GoTradie is not liable for any Consequential Loss arising out of or in any way connected with the GoTradie Services.</p>
<p>33.1.9.4.	Except for liability in relation to a breach of any Non-excludable Condition, GoTradie's liability to any User of the GoTradie Service is limited to the total amount of payment made by that User to GoTradie during the twelve month period prior to any incident causing liability of GoTradie, or AUD$50.00, whichever is greater.</p>
<p>33.1.9.5.	GoTradie's liability to You for a breach of any Non-excludable Condition (other than a Non-excludable Condition that by law cannot be limited) is limited, at our option to any one of resupplying, replacing or repairing, or paying the cost of resupplying, replacing or repairing goods in respect of which the breach occurred, or supplying again or paying the cost of supplying again, services in respect of which the breach occurred.</p>
<p>33.2.	Indemnification</p>
<p>33.2.1.	You agree to indemnify, defend and hold harmless us and our officers, directors, employees, agents, information providers, partners, advertisers, licensors and suppliers from and against all losses, expenses, damages and costs, including reasonable solicitor's fees, resulting from any violation of these Terms and Conditions or any activity related to your service account (including infringement of third parties' intellectual property rights anywhere in the world or negligent or wrongful conduct) by you or any other person accessing the hipages.com.au Web Site using your service account. This indemnity does not apply to the extent that we acted negligently or wilfully engaged in misconduct.</p>
<p>33.3.	Waiver, Release and Limitation of Liability</p>
<p>33.3.1.	Except in respect of the Consumer Guarantees and to the extent permitted by law, you agree that neither we, nor our officers, directors, employees, agents, information providers, partners, advertisers, licensors or suppliers shall have any liability to you under any theory of liability or indemnity in connection with your use of the hipages.com.au Web Site. Our liability for any claim arising from a breach of any term or condition implied by any law which may not be excluded (including, without limitation, the Consumer Guarantees) will be limited to the maximum extent permissible which, in the case of services, will be to the re-supply of the relevant services or the payment of the cost of having the relevant services re-supplied.</p>
<p>33.3.2.	Notwithstanding the foregoing paragraph, except in respect of the Consumer Guarantees and to the extent permitted by law, the total liability of us, our officers, directors, employees, agents, information providers, partners, advertisers, licensors and suppliers, if any, for any loss or damage arising in connection with the hipages.com.au Web Site shall not exceed the greater of the amount of fees paid by you for the particular information or service provided or AUD$200. Except where such limitation is prohibited by law, in no event shall we, our officers, directors, employees, agents, information providers, partners, advertisers, licensors or suppliers be liable to you for any loss or damage other than the amount referred to above, and their liability for all other loss or damage, whether direct or indirect, special, incidental, consequential or punitive, arising from any use of the hipages.com.au Web Site is hereby excluded even if we or our officers, directors, employees, agents, information providers, partners, advertisers, licensors or suppliers have been advised of the possibility of such damages.</p>
<p>33.3.3.	Except in relation to a Consumer Guarantee, neither we, nor any of our affiliates, directors, officers or employees, nor any third party vendor will be liable or have any responsibility of any kind for any loss or damage that you incur in the event of any failure or interruption of the hipages.com.au Web Site, or resulting from the act or omission of any other party involved in making the hipages.com.au Web Site or the information contained therein available to you, or from any other cause relating to your access to or your inability to access the hipages.com.au Web Site or that information, except to the extent we acted negligently or engaged in wilful misconduct.</p>
<p>33.4.	Third Party Rights</p>
<p>33.4.1.	The provisions of Indemnification and the Waiver, Release and Limitation of Liability are for our benefit and the benefit of our officers, directors, employees, agents, partners, advertisers, information providers, licensors and suppliers. Each of these individuals or entities shall have the right to assert and enforce those provisions directly against you on its own behalf.</p>
<p>33.5.	Miscellaneous</p>
<p>33.5.1.	Advertisers and sponsors of the hipages.com.au Web Site and/or its e-newsletters have no influence on editorial content or presentation.</p>
<p>33.5.2.	You may not assign or transfer any of your rights or obligations under these Terms and Conditions without our prior written consent.</p>
<p>33.5.3.	Our relationship with you under these Terms and Conditions is one of independent contractors and not of partnership, joint venture or principal and agent.</p>
<p>33.5.4.	We will send any legal notices or communications regarding your service account to your nominated email address. Please ensure that a valid email address is recorded at all times. Your primary point of contact with us should be via the "Contact Us" facility on the hipages.com.au Web Site. Any formal notices or communications should be sent to our postal address set out at the beginning of these Terms and Conditions. A notice sent to you by email or facsimile will be deemed to have been received by you 24 hours after it was sent, regardless of whether or not you have actually read the notice. Notices sent by mail will be deemed to have been received 3 days after the date of mailing (7 days if sent to or from outside Australia).</p>
<p>33.5.5.	If any clause or provision of these Terms and Conditions is determined to be illegal, invalid or unenforceable or capable of termination by a party in any jurisdiction in which these Terms and Conditions are to operate, then such clause or provision will be construed, to the extent feasible, to render the clause or provision enforceable. If no feasible interpretation would save such clause or provision, it will be severed from the remainder of these Terms and Conditions without affecting the enforceability of all remaining clauses and provisions.</p>
<p>33.5.6.	These Terms and Conditions are governed by the laws of New South Wales, Australia.</p>
<h2>Other Points to possibly work into the T&C’s </h2>
<p>Note that the invoice only becomes a tax invoice on full payment </p>
<p>Note that the contract for good and services is subject to the NSW Building and Construction Security of Payment Act 1999</p>
<p>Payment terms and penalties for late payment </p>
<p>Title of Goods if payment not made </p>
<p>Tradie License details / contact details </p>
<p>Quote # Link   / If No Quote (Discuss with S and A) </p>
 
<h2>GoTradie Glossary </h2>
<p>"Agreement" means the most updated version of the agreement between GoTradie and a User.</p>
<p>"Agreed Price" means agreed price for Services (including any variation) paid into the Payment Account made by the Seeker but does not include any costs incurred by the Tradie when completing Services which the Seeker agrees to reimburse.</p>
<p>"GoTradie" means "we" "us" "our" means the legal entity prescribed in Your Country Specific Terms.</p>
<p>"GoTradie Badge" means a badge that may be issued to a User based on the User meeting certain qualifications or other thresholds, including Verification Icons, as determined and set by GoTradie.</p>
<p>"GoTradie Platform" means the GoTradie website at http://www.GoTradie.com.au, GoTradie smartphone app, and any other affiliated platform that may be introduced from time to time.</p>
<p>"GoTradie Service" means the service of providing the GoTradie Platform.</p>
<p>"Badge" means an GoTradie Badge and Verification Icon.</p>
<p>"Business Day" means a day on which banks are open for general business in the jurisdiction where Users have their GoTradie Platform account, other than a Saturday, Sunday or public holiday.</p>
<p>"Business Partner Contract" means a contract between a Business Partner and a Tradie to perform Business Services.</p>
<p>"Business Partner" means the business or individual that enters into an agreement with GoTradie to acquire Business Services.</p>
<p>"Business Services" means Services provided by a Tradie to a Business Partner acquired for the purpose of on selling to a third party (such as the Business Partner's customer).</p>
<p>"Cancellation Admin Fee" means the Fee payable by a Seeker or a Tradie for cancelling a Job Contract and will not exceed 22% of the Agreed Price.</p>
<p>"Consequential Loss" means any loss, damage or expense recoverable at law:</p>
<ul>
    <li>(a) other than a loss, damage or expense that would be suffered or incurred by any person in a similar situation to the person suffering or incurring the loss, damage or expense; or</li>
    <li>(b) which is a loss of:</li>
    <ul>
        <li>a. opportunity or goodwill;</li>
        <li>b. profits, anticipated savings or business;</li>
        <li>c. data; or</li>
        <li>d. value of any equipment,</li>
    </ul>
</ul>	
<p>and any costs or expenses incurred in connection with the foregoing.</p>
<p>"Country Specific Terms" means those terms set out in Appendix B.</p>
<p>"Fees" means all fees payable to GoTradie by Users including the Service Fee.</p>
<p>"Identity Verification Services" means the tools available to help Users verify the identity, qualifications or skills of other Users including mobile phone verification, verification of payment information, References, integration with social media, GoTradie Badges and Verification Icons.</p>
<p>"Instant Claim" means the acceptance of an Offer by a Tradie via the Search Assist function.</p>
<p>"Marketing Material" means any updates, news and special offers in relation to GoTradie or its Third Party Services.</p>
<p>"Non-excludable Condition" means any implied condition, warranty or guarantee in a contract, the exclusion of which would contravene the law or cause any part of the contract to be void. Further detail on the Non-excludable Conditions for consumers in the United Kingdom and in Ireland is set out in the relevant Your Country Specific Terms.</p>
<p>"Occurrence" means each individual occurrence of services to be performed by a Tradie that form part of Recurring Services.</p>
<p>"Offer" means an offer made by a Tradie in response to a Posted Job to perform the Services. or an offer made by a Seeker for the performance of Services by a Tradie when using Search Assist</p>
<p>"Payment Account" means the account operated by the Payment Provider.</p>
<p>"Payment Provider" means an entity appointed by GoTradie that manages and operates the Payment Account including accepting payments from and making payments to Users.</p>
<p>"Personal Information" has the same meaning as described in Your Country Specific Terms.</p>
<p>"Policies" means the policies posted by GoTradie on the GoTradie Platform, including but not limited to the Community Guidelines.</p>
<p>"Seeker" means a User that posts on the GoTradie Platform in search of particular Services.</p>
<p>"Posted Job" means the Seeker's request for Services published on the Platform (including via Search Assist), and includes the deadline for completion, price and description of the Services to be provided.</p>
<p>"Seeker Service Fee" means the fee payable by the Seeker to GoTradie as consideration for the GoTradie Services (and comprised as part of the Agreed Price) displayed to a Seeker prior to entering into each Job Contract</p>
<p>"Recurring Services" means the same services procured by a Seeker using the Search Assist feature from the same Tradie on a recurring basis, for example weekly, fortnightly or monthly.</p>
<p>"Reference" means a feature allowing a User to request other Users to post a reference on the GoTradie Platform endorsing that User.</p>
<p>"Search Assist" means a feature of the GoTradie Platform whereby a Seeker can submit specific details of a Posted Job with GoTradie's assistance to calculate the Agreed Price and find potential Tradies to perform the Services. This may also be referred to on the GoTradie Platform as "Instant Booking".</p>
<p>"Service Fee" means the Seeker Service Fee and the Tradie Service Fee.</p>
<p>"Services" means the services to be rendered as described in the Posted Job, including any variations or amendments agreed before or subsequent to the creation of a Job Contract and for Recurring Services the Services are the services to be performed under each Occurrence.</p>
<p>"Stored Value" means the physical or virtual card, coupon, voucher or code containing credit or a discount or refund provided as credit or anything else identified or described as 'Stored Value' in this Agreement, for use on the GoTradie Platform.</p>
<p>"Job Contract" means the separate contract which is formed between a Seeker and a Tradie for Services. In the absence of, or in addition to, any terms specifically agreed, the model terms of which are included in Appendix A to the Agreement apply to Job Contracts.</p>
<p>"Tradie" means a User who provides Services to Seekers.</p>
<p>"Tradie Funds" means the Agreed Price less the Service Fee.</p>
<p>"Tradie Service Fee" means the fee payable by the Tradie to GoTradie as consideration for the GoTradie Services (and comprised as part of the Agreed Price) displayed to a Tradie prior to entering into each Job Contract.</p>
<p>"Third Party Dispute Service" means a third party dispute resolution service provider used to resolve any disputes between Users.</p>
<p>"Third Party Service" means the promotions and links to services offered by third parties as may be featured on the GoTradie Platform from time to time.</p>
<p>"User" or "You" means the person who has signed up to use the GoTradie Platform, whether as the Seeker, Tradie, or otherwise.</p>
<p>"Verification Icons" means the icons available to be displayed on a User's profile and any such posts on the GoTradie Platform to confirm details such as a User's qualification, license, certificate or other skill.</p>
<h2>Rules of Interpretation:</h2>
<p>In the GoTradie Agreement and all Policies, except where the context otherwise requires:</p>
<ul>
    <li>(a) the singular includes the plural and vice versa, and a gender includes other genders;</li>
    <li>(b) another grammatical form of a defined word or expression has a corresponding meaning;</li>
    <li>(c) a reference to a document or instrument includes the document or instrument as novated, altered, supplemented or replaced from time to time;</li>
    <li>(d) the applicable currency shall be the currency specified in Your Country Specific Terms;</li>
    <li>(e) a reference to a person includes a natural person, partnership, body corporate, association, governmental or local authority or agency or other entity;</li>
    <li>(f) a reference to a statute, ordinance, code or other law includes regulations and other instruments under it and consolidations, amendments, re-enactments or replacements of any of them;</li>
    <li>(g) the meaning of general words is not limited by specific examples introduced by including, for example or similar expressions;</li>
    <li>(h) headings are for ease of reference only and do not affect interpretation;</li>
    <li>(i) any agreement, representation, warranty or indemnity in favour of two or more parties (including where two or more persons are included in the same defined term) is for the benefit of them jointly and severally; and</li>
    <li>(j) a rule of construction does not apply to the disadvantage of a party because the party was responsible for the preparation of this agreement or any part of it.</li>
</ul>
<p>Drafted January 2021 copyright GoTradie 2021</p>	


                </div>
            </Policy>
        </>
    </PageTemplate>
  );
};

export default TermsAndConditionsPage;
/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 3:06:50 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 3:06:50 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTemplate from "../../components/templates/BlankTemplate";
import Settlement from "../../components/organisms/Settlement"

import './styles.scss';


import api from "../../api";
import _ from "lodash";
const source = _.get(api, "payments");
const report_source = _.get(api, "reporting");

const InvoicePaymentPage = () => {
  const { code } = useParams();
  const [isLoading, setLoading] = useState(false);

  const [mode, setMode] = useState('');
  const [data, setData] = useState(null);

  const EVENT_STATUS = {
    FAIL: 'fail',
    SUCCESS: 'success'
  }

  useEffect(() => {
    if (source) {
        setLoading(true);
        setMode('initial-settlement');
        
        const token = getParam('token');
        const rejected = getParam('rejected');

        if(rejected) {
            setLoading(false);
            setMode("settlement-failure");
            reportEvent(EVENT_STATUS.FAIL, `Payment rejected`, code);
            return;
        }
        
        source
            .postPaymentSettlement({
                'invoice-id': code,
                'settlement-token': token
            })
            .then((res) => {
                setMode("settlement-success");
                setData({value:code, token});
                console.log('result of payment settlement call to GoTradie backend', res)
                reportEvent(EVENT_STATUS.SUCCESS, ``, code);
            })
            .catch((err) => {
                console.log('Error! Payment settlement api error.', err);
                setMode("backend-error");
                reportEvent(EVENT_STATUS.FAIL, `Unable to call settlement endpoint`, code, err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function getParam(param) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        return params.get(param);
    }

    
    function reportEvent(status, message, code, data = 'none') {
      report_source.reportEvent({
        'event-type': 'payments-settlement',
        'user-id': 'system',
        'user-event': { data },
        'data': { status: `${status} payment`, 'error': message },
        'source-type': 'invoice',
        'source-id': code
      });
    }

  }, []);

  
  return (
    <PageTemplate>
      <Helmet>
        <title>{`GoTradie | Invoice Payment Settlement`}</title>
      </Helmet>
      <>
        <Settlement isLoading={isLoading} mode={mode} data={data} />
      </>
    </PageTemplate>
  );
};

export default InvoicePaymentPage;
/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:28:36 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:28:36 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Container } from "react-bootstrap";
import PolicyHeader from "../../molecules/PolicyHeader";
import PolicyBody from "../../molecules/PolicyBody";

import "./styles.scss";


const Policy = (props) => {
const {title} = props;
    return (
        <Container className={"pt-2 py-2"}>
            <PolicyHeader title={title} />
            <PolicyBody>
                {props.children}
            </PolicyBody>
        </Container>
    )

}

export default Policy;

/*
 * File: index.js
 * File Created: Monday, 31st August 2020 5:06:15 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Monday, 31st August 2020 5:06:16 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import axios from "axios";

import quotes from "./quotes";
import invoice from "./invoice";
import payments from "./payments";
import reporting from "./reporting";
import emailVerify from "./emailVerify";

const API_URL = process.env.REACT_APP_API_ENDPOINT;
const API_KEY = process.env.REACT_APP_API_KEY;

axios.defaults.baseURL = API_URL;
const { appVersion, userAgent } = window.navigator;
const source = process.env.REACT_APP_PRODUCT;
//axios.defaults.headers.common["x-source"] = source;
//axios.defaults.headers.common["x-device"] = appVersion;
//axios.defaults.headers.common["x-browser"] = userAgent;
axios.defaults.headers.common["x-api-key"] = API_KEY;

export default {
  quotes: quotes(axios, `/quotes`),
  invoice: invoice(axios, `/invoice`),
  payments: payments(axios, `/payments`),
  reporting: reporting(axios, `/reporting`),
  emailVerify: emailVerify(axios, `/signup`)
};

/*
 * File: index.js
 * File Created: Sunday, 11th October 2020 10:40:50 am
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Sunday, 11th October 2020 10:40:50 am
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Alert } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import "./styles.scss";


const InvoiceFooter = (props) => {
    const {job_breakdown, terms} =props;
    return (
        <>
            <Row className={"invoice-footer-top pt-2 pb-4"}>
                {job_breakdown && <Col>
                    <Typography Tag={"p"} className={"label_jobdes py-1"}>{"Job breakdown"}</Typography>
                    <Typography Tag={"p"}>{job_breakdown}</Typography>
                </Col>}
            </Row>
            {/* <Row className={"invoice-footer-bottom py-2"}>
                <Col>
                    <Typography Tag={"p"} className={"label_terms_and_cond py-1"}>{"Terms and conditions"}</Typography>
                    <Typography Tag={"span"}>{terms}</Typography>
                </Col>
            </Row> */}
        </>
    );

}

export default InvoiceFooter;
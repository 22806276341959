import React, {useState} from "react";
import {Image, Container, Row, Col, Button, Table, Spinner} from "react-bootstrap";
import Typography from "../../atoms/Typography";
import {CopyToClipboard} from "react-copy-to-clipboard";

import "./styles.scss";
import Link from "../../atoms/Link";

const VerifyEmailBody = (props) => {

    const {status} = props;

    if (status === null) {
        return <Loading/>
    }
    else if (status === 'success') {
        return <EmailVerified
            titleText1={"All sorted!"}
            titleText2={"Your email has been verified"}
        />
    } else if (status === 'already-validated') {
        return <EmailVerified
            titleText1={"All sorted!"}
            titleText2={"Your email has been already verified"}
        />
    } else if (status === 'expired') {
        return <LinkInvalid
            titleText1={"Sorry,"}
            titleText2={"looks like you were too late to the party"}
            linkStatus={"expired"}
        />
    } else {
        return <LinkInvalid
            titleText1={"Sorry,"}
            titleText2={"looks like something stuffed up"}
            linkStatus={"invalid"}
        />
    }
}

const Loading = () => {
    return (
        <div className={"d-flex align-items-center"}>
            <Spinner animation="border"/>
        </div>
    )
}

const EmailVerified = ({titleText1, titleText2}) => {
    const url = "www.gotradie.com.au"
    const [isCopied, setIsCopied] = useState(false);
    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };
    return (
        <>
            <Row>
                <Col xs={12} className={"d-flex align-items-center main-text"}>
                    <Title
                        titleText1={titleText1}
                        titleText2={titleText2}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={"d-flex align-items-center"}>
                    <Typography
                        className={"sub-text"}>{"If you have downloaded the app already you beauty. If not go ahead and download the app below."}
                    </Typography>
                </Col>
            </Row>
            <Row className={"download-icon-container"}>
                <Col className={"d-flex align-items-right"}>
                    <Image src={'/download-ios.svg'} className={"app-download-image"}/>
                </Col>
                <Col className={"d-flex align-items-left"}>
                    <Image src={'/download-android.svg'} className={"app-download-image"}/>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={"d-flex align-items-center"}>
                    <Typography
                        className={"sub-text sub-text-2"}>{"Know a tradie or seeker looking for work? Pass on the good word about GoTradie."}
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Container className={"url-container"}>
                    <Row>
                        <Col className={"d-flex"}>
                            <p className={"sub-text url"}>
                                {url}
                            </p>
                        </Col>
                        <Col className={"d-flex  align-items-right"}>
                            <CopyToClipboard text={url} onCopy={onCopyText}>
                                <p className={"sub-text copy"}>
                                    {isCopied ? "Copied!" : "Copy"}
                                </p>
                            </CopyToClipboard>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </>
    )
}

const Title = (titles) => {
    const {titleText1, titleText2} = titles
    return (
        <h2 className={"main-text"}>
            <span className={"main-text status"}>{titleText1}</span>{' '}{titleText2}
        </h2>
    )
}

const LinkInvalid = ({titleText1, titleText2, linkStatus}) => {
    const email = "info@gotradie.com.au"
    return (
        <>
            <Row>
                <Col xs={12} className={"d-flex align-items-center main-text"}>
                    <Title
                        titleText1={titleText1}
                        titleText2={titleText2}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={"d-flex align-items-center"}>
                    <p className={"sub-text"}>
                        Your link is <span className={"bold"}>{linkStatus}.</span> Don’t worry simply click the link
                        below
                        and we’ll send you a new link.
                    </p>
                </Col>
            </Row>
            <Row className={"align-items-center"}>
                <Button className={"email-new-link"}>Email me new link</Button>
            </Row>
            <Row>
                <Col xs={12} className={"d-flex align-items-center"}>
                    <Typography
                        className={"sub-text sub-text-2"}>{"Keep hitting this screen? Contact us at GoTradie and one of the team will sort you out."}
                    </Typography>
                </Col>
            </Row>
            <Row className={"align-items-center email-container"}>
                <Image className={"email-icon"} src={"/email.svg"}/>
                <Link className={"email-address"} href={`mailto:${email}`}>{email}</Link>
            </Row>
        </>
    )
}


export default VerifyEmailBody;

/*
 * File: index.js
 * File Created: Sunday, 11th October 2020 11:19:33 am
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Sunday, 11th October 2020 11:19:34 am
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */

import Utils from "../utils"

const MaterialModel = (obj) => {
    let createObjdata = {
        "material_unit_price": obj["material-unit-price"],
        "material_total": obj["material-total"],
        "total_volume": obj["total-volume"],
        "material_item-price": obj["material-item-price"],
        "material_name": obj["material-name"]
    };

    return createObjdata;
}

const QuotesModel = (data) => {
    const obj = data["quotes"];
    const quote = {};
    quote["labour_hours"] = Utils.replaceByDefault(obj, "approximate-time", 0);
    quote["sub_total"] = Utils.replaceByDefault(obj, "total-cost", 0);
    quote["gst"] = Utils.replaceByDefault(obj, "gst-cost", 0);
    quote["total"] = (quote["sub_total"] + quote["gst"]);
    quote["job_breakdown"] = Utils.replaceByDefault(obj, "job-breakdown", "");
    quote["terms"] = Utils.replaceByDefault(data["metadata"], "terms", "");
    quote["material_margin"] = Utils.replaceByDefault(obj, "margin-on-materials", 0);
    const ar_materials = [];
    let material_cost = 0;

    if (obj["quote-material-items"] !== undefined && obj["quote-material-items"] !== null) {
        obj["quote-material-items"].forEach(element => {
            const material = MaterialModel(element);
            if (material !== undefined) {
                material_cost += parseFloat(Utils.replaceByDefault(material, "material_total", 0));
            }

            ar_materials.push(material);
        });
    }
    quote["materials"] = ar_materials;
    quote["material_total"] = (material_cost + (material_cost * quote["material_margin"] / 100));
    quote["labour_cost"] = (quote["sub_total"] - quote["material_total"]);
    console.log(quote);
    return {
        "quote_header": {
            "profile": data["profile"],
            "quote_id" : obj["quote-id"]
        },
        "quote_body": {
            "labour_cost": quote["labour_cost"],
            "material_total": quote["material_total"],
            "materials": quote["materials"],
            "sub_total": quote["sub_total"],
            "gst": quote["gst"],
            "total": quote["total"],
            "labour_hours": quote["labour_hours"]
        },
        "quote_footer": {
            "job_breakdown": quote["job_breakdown"],
            "terms": quote["terms"]
        }
    };
}


const InvoiceModel = (data) => {
    const obj = data["invoices"];
    const invoice = {};
    invoice["labour_hours"] = Utils.replaceByDefault(obj, "actual-time", 0);
    invoice["sub_total"] = Utils.replaceByDefault(obj, "total-cost", 0);
    invoice["gst"] = Utils.replaceByDefault(obj, "gst-cost", 0);
    invoice["total"] = (invoice["sub_total"] + invoice["gst"]);
    invoice["job_breakdown"] = Utils.replaceByDefault(obj, "job-breakdown", "");
    invoice["terms"] = Utils.replaceByDefault(data["metadata"], "terms", "");
    invoice["material_margin"] = Utils.replaceByDefault(obj, "margin-on-materials", 0);
    const ar_materials = [];
    let material_cost = 0;

    if (obj["invoice-material-items"] !== undefined && obj["invoice-material-items"] !== null) {
        obj["invoice-material-items"].forEach(element => {
            const material = MaterialModel(element);
            if (material !== undefined) {
                material_cost += parseFloat(Utils.replaceByDefault(material, "material_total", 0));
            }

            ar_materials.push(material);
        });
    }
    invoice["materials"] = ar_materials;
    invoice["material_total"] = (material_cost + (material_cost * invoice["material_margin"] / 100));
    invoice["labour_cost"] = (invoice["sub_total"] - invoice["material_total"]);
    console.log(invoice);
    return {
        "invoice_header": {
            "profile": data["profile"],
            "invoice_date":obj["invoice-date"],
            "due_date":obj["invoice-due-date"],
            "customers":Utils.replaceByDefault(obj,"customers",[]),
            "invoice_no":obj["invoice-id"]
        },
        "invoice_body": {
            "labour_cost": invoice["labour_cost"],
            "material_total": invoice["material_total"],
            "materials": invoice["materials"],
            "sub_total": invoice["sub_total"],
            "gst": invoice["gst"],
            "total": invoice["total"],
            "labour_hours": invoice["labour_hours"],
            "direct_bank_profile": obj["payments-direct-bank-profile"],
            "invoice_no":obj["invoice-id"],
            "qp_payment_link": obj["payments-qp-payment-url"]
        },
        "invoice_footer": {
            "job_breakdown": invoice["job_breakdown"],
            "terms": invoice["terms"]
        }
    };
}


export default {
    QuotesModel: QuotesModel,
    InvoiceModel:InvoiceModel
};
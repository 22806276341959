import React from "react";
import {Image, Row, Col} from "react-bootstrap";

import "./styles.scss";

const VerifyEmailHeader = () => {
    return (
        <>
            <Row className={"verify-email-header wrapper-top"}>
                <Col xs={12} className={"d-flex align-items-center"}>
                    <Image src={'/gtlogo.png'} className={"verify-email-header-logo "}/>
                </Col>
            </Row>
        </>
    )
}

export default VerifyEmailHeader;

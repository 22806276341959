/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:31:42 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:31:42 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Alert } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import Utils from "../../../utils";

import "./styles.scss";


const InvoiceHeader = (props) => {
    const { profile, invoice_no, due_date, invoice_date, customers } = props;

    const address_default = Utils.replaceEmptyWithUndefined(profile["tradie-business-address"]);
    const address_nsw = Utils.replaceEmptyWithUndefined(profile["nsw-tradie-business-address"])
    const address = (address_nsw ? address_nsw : address_default);

    console.log(props);

    let customerobj = null;
    if (customers !== undefined && customers.length > 0) {
        customerobj = customers[(customers.length - 1)];
    }

    return (
        <>
            <Row className={"invoice-header"}>
                <Col xs={9}>
                    <Typography Tag={"h5"}>{profile["company-name"]}</Typography>
                    <Typography Tag={"p"}>{Utils.replaceUndefinedWithDefault(address["address"], "")}</Typography>
                    <Typography Tag={"p"}>{Utils.replaceUndefinedWithDefault(profile["phone-number"], "")}</Typography>
                    <Typography Tag={"p"}>{Utils.replaceUndefinedWithDefault(profile["email"], "")}</Typography>
                </Col>
                <Col xs={3} className={"d-flex align-items-center"}>
                    <Image src={profile["profile-pic-url"]} roundedCircle className={"invoice-header-logo "} />
                </Col>
            </Row>
            <Row className={"invoice-header pt-2"}>
                <Col xs={5} >
                    {(customerobj !== null) && (
                        <React.Fragment>
                            <Typography Tag={"small"} className={"text-small-bold-item"}>{`Bill to`}</Typography><br />
                            <Typography Tag={"small"} className={"text-small-item"}>{`${Utils.replaceUndefinedWithDefault(customerobj["name"], "")}`}</Typography><br/>
                            <Typography Tag={"small"} className={"text-small-item"}>{`${Utils.replaceUndefinedWithDefault(customerobj["email"], "")}`}</Typography><br/>
                            <Typography Tag={"small"} className={"text-small-item"}>{`${Utils.replaceUndefinedWithDefault(customerobj["phone"], "")}`}</Typography>
                        </React.Fragment>
                    )}

                </Col>
                <Col xs={7} className={"text-right"}>
                    <div>
                        <Typography Tag={"small"} className={"text-small-bold-item"}>{`Invoice No : `}</Typography>
                        <Typography Tag={"small"} className={"text-small-item"}>{invoice_no}</Typography>
                    </div>
                    <div>
                        <Typography Tag={"small"} className={"text-small-bold-item"}>{`Invoice Date : `}</Typography>
                        <Typography Tag={"small"} className={"text-small-item"}>{Utils.convertDateToCurrnetTimeZone(invoice_date, "MMM DD,YYYY", "X")}</Typography>
                    </div>
                    <div>
                        <Typography Tag={"small"} className={"text-small-bold-item"}>{`Payment Due : `}</Typography>
                        <Typography Tag={"small"} className={"text-small-item"}>{Utils.convertDateToCurrnetTimeZone(due_date, "MMM DD,YYYY", "X")}</Typography>
                    </div>
                </Col>
            </Row>
        </>
    )

}

export default InvoiceHeader;

import React from "react";
import {Container} from "react-bootstrap";
import VerifyEmailHeader from "../../molecules/VerifyEmailHeader";
import VerifyEmailBody from "../../molecules/VerifyEmailBody";
import VerifyEmailFooter from "../../molecules/VerifyEmailFooter";

import "./styles.scss";


const VerifyEmail = (props) => {
    const {status} = props;
    return (
        <Container className={"pt-2 py- bg-gradient bg-full"}>
            <VerifyEmailHeader/>
            <VerifyEmailBody status={status}/>
            <VerifyEmailFooter />
        </Container>
    )
}

export default VerifyEmail;

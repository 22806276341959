/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:31:42 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:31:42 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Alert } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import Utils from "../../../utils";

import "./styles.scss";


const QuoteHeader = (props) => {
    const {profile, quote_id} = props;

    const address_default=Utils.replaceEmptyWithUndefined(profile["tradie-business-address"]);
    const address_nsw=Utils.replaceEmptyWithUndefined(profile["nsw-tradie-business-address"])
    const address=(address_nsw?address_nsw:address_default);
    return (
        <Row className={"quote-header"}>
            <Col xs={9}>
                <Typography Tag={"h5"}>{profile["company-name"]}</Typography>
                <Typography Tag={"p"}>{Utils.replaceUndefinedWithDefault(address["address"],"")}</Typography>
                <Typography Tag={"p"}>{Utils.replaceUndefinedWithDefault(profile["phone-number"],"")}</Typography>
                <Typography Tag={"p"}>{Utils.replaceUndefinedWithDefault(profile["email"],"")}</Typography>

                <div>
                    <Typography Tag={"small"} className={"text-small-bold-item"}>{`Quote No : `}</Typography>
                    <Typography Tag={"small"} className={"text-small-item"}>{quote_id}</Typography>
                </div>
            </Col>
            <Col xs={3} className={"d-flex align-items-center"}>
                <Image src={profile["profile-pic-url"]} roundedCircle className={"quote-header-logo "} />
            </Col>
        </Row>
    )

}

export default QuoteHeader;

/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:38:48 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:38:48 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Table } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import Utils from "../../../utils";
import QRCode from "react-qr-code";

import "./styles.scss";


const InvoiceTable = (props) => {
const {labour_hours,labour_cost,material_total,materials,sub_total,gst,total, direct_bank_profile, qp_payment_link, invoice_no} = props;

  return (
    <React.Fragment>
    <Row className={"invoice-table py-3"}>
      <Col>
        <Table className="table table-sm">
          <thead>
            <tr className={"bg-primary"}>
              <td  align={"center"}><Typography Tag={"p"}>{"Description"}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{"Hours"}</Typography></td>
              <td align={"center"} ><Typography Tag={"p"}>{"Total"}</Typography></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Typography Tag={"p"}>{"Labour"}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{`${labour_hours}`}</Typography></td>
              <td align={"right"}><Typography Tag={"p"}>{`${Utils.convertToPrice(labour_cost)}`}</Typography></td>
            </tr>
            {(materials.length>0) && (
               <tr>
               <td>
                 <Typography Tag={"p"}>{"Material"}</Typography>
                  {materials.map((v,i) => (
                    <Typography Tag={"p"}>{`- ${v["material_name"]}`}</Typography>
                  ))}
               </td>
               <td align={"center"}><Typography Tag={"p"}>{""}</Typography></td>
               <td align={"right"}><Typography Tag={"p"} >{`${Utils.convertToPrice(material_total)}`}</Typography></td>
             </tr>
            )}
            
          </tbody>
          <tfoot>
            <tr>
              <td><Typography Tag={"p"}>{""}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{"SubTotal"}</Typography></td>
              <td align={"right"}><Typography Tag={"p"}>{`${Utils.convertToPrice(sub_total)}`}</Typography></td>
            </tr>
            <tr>
              <td><Typography Tag={"p"}>{""}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{"GST"}</Typography></td>
              <td align={"right"}><Typography Tag={"p"}>{`${Utils.convertToPrice(gst)}`}</Typography></td>
            </tr>
            <tr>
              <td><Typography Tag={"p"}>{""}</Typography></td>
              <td align={"center"}><Typography Tag={"p"} className={"invoice-total"}>{"Invoice total"}</Typography></td>
              <td align={"right"}><Typography Tag={"p"} className={"invoice-total"}>{`${Utils.convertToPrice(total)}`}</Typography></td>
            </tr>
          </tfoot>
        </Table>
        
      </Col>
    </Row>
    <Row className={"invoice-table py-3"}>
      <Col xs={8} md={9} lg={10}>
        {direct_bank_profile && <Table className="table table-sm">
          <thead>
            <tr className={"bg-primary"}>
              <td align={"left"}><Typography Tag={"p"}>{"Direct deposit"}</Typography></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                    <Typography Tag={"small"} className={"text-small-item"}>{"BSB"}</Typography>
                    <Typography Tag={"small"} className={"text-small-bold-item margin-left"}>{direct_bank_profile["bsb"]}</Typography>
                </div>
                <div>
                    <Typography Tag={"small"} className={"text-small-item"}>{"Account number"}</Typography>
                    <Typography Tag={"small"} className={"text-small-bold-item margin-left"}>{direct_bank_profile["account-number"]}</Typography>
                </div>
                <div>
                    <Typography Tag={"small"} className={"text-small-item"}>{"Account name"}</Typography>
                    <Typography Tag={"small"} className={"text-small-bold-item margin-left"}>{direct_bank_profile["account-name"]}</Typography>
                </div>
                <div>
                    <Typography Tag={"small"} className={"text-small-item"}>{"Reference"}</Typography>
                    <Typography Tag={"small"} className={"text-small-bold-item margin-left"}>{invoice_no}</Typography>
                </div>
              </td>
            </tr>          
          </tbody>
        </Table>}        
      </Col>
      <Col xs={4} md={3} lg={2}>
        {qp_payment_link && <div>
          <Image src={'/qp-logo-small.png'} className={"logo"} />
          <a href={qp_payment_link} target="_blank">
            <QRCode value={qp_payment_link} size={90} />
          </a>
        </div>}
      </Col>
    </Row>
    </React.Fragment>
  )

}

export default InvoiceTable;
/*
 * File: invoice.js
 * File Created: Tuesday, 5th January 2021 12:15:29 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Tuesday, 5th January 2021 12:15:29 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */

export default (axios, base) => ({
    getInvoice: (data) => {
      return axios.get(`${base}/${data.code}`);
    },

    getInvoicePaymentInfo: (data) => {
      return axios.get(`${base}/${data.code}/paymentinfo`);
    }
  });
  
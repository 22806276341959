import React from "react";
import {Row, Container} from "react-bootstrap";
import Link from "../../atoms/Link";

import "./styles.scss";

const VerifyEmailFooter = () => {
    return (
        <>
            <Row className={"verify-email-footer "}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Link className={"text"} href={"/"}>© 2021 by GoTradie™</Link>
                        <Link className={"text center-text"} href={"/privacy-policy"}>Privacy policy</Link>
                        <Link className={"text"} href={"#"}>Contact us</Link>
                    </Row>
                </Container>
            </Row>
        </>
    )
}

export default VerifyEmailFooter;

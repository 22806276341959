/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:28:36 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:28:36 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Alert } from "react-bootstrap";
import InvoiceHeader from "../../molecules/InvoiceHeader";
import InvoiceTable from "../../molecules/InvoiceTable";
import InvoiceFooter from "../../molecules/InvoiceFooter";

import "./styles.scss";


const InvoiceBody = (props) => {
const {invoice} = props;
    return (
        <Container className={"pt-2 py-2"}>
          <InvoiceHeader {...invoice["invoice_header"]} />
          <InvoiceTable {...invoice["invoice_body"]}/>
          <InvoiceFooter {...invoice["invoice_footer"]}/>
        </Container>
    )

}

export default InvoiceBody;

import React from "react";
import { Container } from "react-bootstrap";
import Loader from "../../molecules/PaymentLoader";

import "./styles.scss";


const Payment = (props) => {
const { isLoading, mode } = props;
    return (
        <Container className={"pt-2 py-2 bg-gradient bg-full"}>
          <Loader mode={mode} isLoading={isLoading} />
        </Container>
    )

}

export default Payment;

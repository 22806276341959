/*
 * File: common.js
 * File Created: Saturday, 5th September 2020 6:14:17 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 5th September 2020 6:14:17 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
const API_URL = process.env.REACT_APP_API_ENDPOINT;
const API_KEY = process.env.REACT_APP_API_KEY;
export default (axios, base) => ({
  getQuote: (data) => {
    return axios.get(`${base}/${data.code}`);
  },
  getQuoteOld: (data) => {
    const url=`${API_URL}${base}/${data.code}`;
    console.log(url)
    return fetch(url, {
      timeout: 10000,
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': API_KEY
      }
  });
  },
});

/*
 * File: index.js
 * File Created: Saturday, 10th October 2020 4:38:48 pm
 * Author: Buwaneka (buwanekasumanasekara@gmail.com)
 * -----
 * Last Modified: Saturday, 10th October 2020 4:38:48 pm
 * Modified By: Buwaneka (buwanekasumanasekara@gmail.com>)
 * --------------------------------------------------------------
 */
import React from "react";
import { Image, Container, Row, Col, Button, Table } from "react-bootstrap";
import Typography from "../../atoms/Typography";
import Utils from "../../../utils";

import "./styles.scss";


const QuoteTable = (props) => {
const {labour_hours,labour_cost,material_total,materials,sub_total,gst,total} = props;
  return (
    <Row className={"quote-table py-3"}>
      <Col>
        <Table className="table table-sm">
          <thead>
            <tr className={"bg-primary"}>
              <td  align={"center"}><Typography Tag={"p"}>{"Description"}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{"Hours"}</Typography></td>
              <td align={"center"} ><Typography Tag={"p"}>{"Total"}</Typography></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Typography Tag={"p"}>{"Labour"}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{`${labour_hours}`}</Typography></td>
              <td align={"right"}><Typography Tag={"p"}>{`${Utils.convertToPrice(labour_cost)}`}</Typography></td>
            </tr>
            {(materials.length>0) && (
               <tr>
               <td>
                 <Typography Tag={"p"}>{"Material"}</Typography>
                  {materials.map((v,i) => (
                    <Typography Tag={"p"}>{`- ${v["material_name"]}`}</Typography>
                  ))}
               </td>
               <td align={"center"}><Typography Tag={"p"}>{""}</Typography></td>
               <td align={"right"}><Typography Tag={"p"} >{`${Utils.convertToPrice(material_total)}`}</Typography></td>
             </tr>
            )}
            
          </tbody>
          <tfoot>
            <tr>
              <td><Typography Tag={"p"}>{""}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{"SubTotal"}</Typography></td>
              <td align={"right"}><Typography Tag={"p"}>{`${Utils.convertToPrice(sub_total)}`}</Typography></td>
            </tr>
            <tr>
              <td><Typography Tag={"p"}>{""}</Typography></td>
              <td align={"center"}><Typography Tag={"p"}>{"GST"}</Typography></td>
              <td align={"right"}><Typography Tag={"p"}>{`${Utils.convertToPrice(gst)}`}</Typography></td>
            </tr>
            <tr>
              <td><Typography Tag={"p"}>{""}</Typography></td>
              <td align={"center"}><Typography Tag={"p"} className={"quote-total"}>{"Quote total"}</Typography></td>
              <td align={"right"}><Typography Tag={"p"} className={"quote-total"}>{`${Utils.convertToPrice(total)}`}</Typography></td>
            </tr>
          </tfoot>
        </Table>
        
      </Col>
    </Row>
  )

}

export default QuoteTable;